import { ActionTree } from 'vuex';
import { api } from 'boot/axios';
import { AxiosResponse } from 'axios';
import { TransactionMethodInterface } from 'src/interfaces/TransactionMethodInterface';
import { StateInterface } from '../index';
import { AccountJournalsStateInterface } from '.';

const actions: ActionTree<AccountJournalsStateInterface, StateInterface> = {

  async fetchAccountJournals(context, params: { pmsPropertyId: number, roomIds?: number[] }) {
    let accountParams = `?pmsPropertyId=${params.pmsPropertyId}`;
    if (params.roomIds) {
      params.roomIds.forEach((el) => {
        accountParams += `&roomIds[]=${el.toString()}`;
      });
    }
    return api
      .get(`/account-journals${accountParams}`)
      .then((response: AxiosResponse<TransactionMethodInterface[]>) => {
        context.commit('SET_ACCOUNT_JOURNALS', response.data);
      });
  },

};

export default actions;
